.container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.directionContainer {
  width: 100vw;
  height: 100vh;
  position: absolute;
  z-index: 0;
}

.directionContainer h1 {
  position: absolute;
  top: 15%;
  right: 50%;
  transform: translateX(50%);
  user-select: none;
}

@media (max-width: 960px) {
  .directionContainer h1 {
    top: 7%;
    right: 25%;
    font-size: 1.2rem;
  }
}
