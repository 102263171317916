:root {
  --page-width: 960px;
  --header-height: 120px;
  --text-color: #1e1e1e;
  --paragraph-color: #313131;
  --gh-color: #292929;
  --button-color: #cfcfcf;
  --border-radius: 16px;
  --background-page: rgb(248, 248, 248);
  --portafolio-color: #ffffff;
}

@font-face {
  font-family: "Minecraft";
  src: url("../font/Minecraft.ttf");
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-weight: 100;
}

html {
  background-color: var(--background-page);
}

body {
  width: 100vw;
  height: 100vh;
  font-family: "Minecraft", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--background-page);
}
